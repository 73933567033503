// extracted by mini-css-extract-plugin
export var container = "quarantine-top-styles-module--container--ZbuFd";
export var imgContainer = "quarantine-top-styles-module--img-container--3+3Jt";
export var pqr = "quarantine-top-styles-module--pqr--D1KX-";
export var textContainer = "quarantine-top-styles-module--text-container--JZnyN";
export var buttonContainer = "quarantine-top-styles-module--button-container--OJCmo";
export var button = "quarantine-top-styles-module--button--6tJzI";
export var diagram = "quarantine-top-styles-module--diagram--HTIED";
export var box = "quarantine-top-styles-module--box--BQW3g";
export var arrow = "quarantine-top-styles-module--arrow--dSL6X";
export var mediaContainer = "quarantine-top-styles-module--media-container--f9Clk";
export var mediaImgContainer = "quarantine-top-styles-module--media-img-container---XUg4";
export var mediaImg = "quarantine-top-styles-module--media-img--i0FqV";