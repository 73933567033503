// extracted by mini-css-extract-plugin
export var container = "quarantine-article-module--container--XxzAW";
export var articleContainer = "quarantine-article-module--article-container--mDTzF";
export var article = "quarantine-article-module--article--v4W8u";
export var title = "quarantine-article-module--title--NTJcl";
export var country = "quarantine-article-module--country--fDGV3";
export var author = "quarantine-article-module--author--KaL8w";
export var imageContainer = "quarantine-article-module--image-container--bDLMe";
export var image = "quarantine-article-module--image--MJJPI";
export var overlay = "quarantine-article-module--overlay--WCZ+1";
export var mainArticle = "quarantine-article-module--main-article--amdtP";
export var mainTextContainer = "quarantine-article-module--main-text-container--PRG6d";
export var excerpt = "quarantine-article-module--excerpt--qiXMK";